import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, IGatsbyImageData, ImageDataLike } from "gatsby-plugin-image";

import BackgroundImageBase from "../base/BackgroundImage.base";
import { mergeStyles } from "@fluentui/react";

interface ImageGraphQL {
    placeholderImage: ImageDataLike;
}

interface BackgroundProps {
    className?: string;
}

const Background: React.FunctionComponent<BackgroundProps> = (props: BackgroundProps) => {
    const { className } = props;

    const data: ImageGraphQL = useStaticQuery(graphql`
        query {
            placeholderImage: file(relativePath: { eq: "background.png" }) {
                childImageSharp {
                    gatsbyImageData(width: 300)
                }
            }
        }
    `);

    const image = getImage(data.placeholderImage) as IGatsbyImageData;

    const backgoundImage = mergeStyles(
        {
            position: "absolute",
            height: "100%",
            flexGrow: 1,
            opacity: ".75",
        },
        className
    );

    return <BackgroundImageBase image={image} className={backgoundImage} />;
};

export default Background;
