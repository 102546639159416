import React from "react";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { mergeStyleSets } from "@fluentui/react";

interface ImageBaseProps {
    image: IGatsbyImageData;
    className: string;
}

const BackgroundImageBase: React.FunctionComponent<ImageBaseProps> = (props: ImageBaseProps) => {
    const { image, className } = props;

    const imgClass = mergeStyleSets({
        imgRoot: {
            width: "100vw",
            height: "100%",
            margin: "0px",
            padding: "0px",
            selectors: {
                "@media(max-width: 600px)": {
                    width: `90vw`,
                },
            },
        },
    });

    return (
        <div className={className}>
            <GatsbyImage alt="" image={image} className={imgClass.imgRoot} />
        </div>
    );
};

export default BackgroundImageBase;
