import React, { PropsWithChildren, CSSProperties } from "react";

interface MPNLogoProps {
    width: number;
    inverted?: boolean;
}

const MPNLogo: React.FunctionComponent<MPNLogoProps> = (props: PropsWithChildren<MPNLogoProps>) => {
    const { width, inverted = true } = props;
    const color = inverted ? "#FFF" : "#000";

    const svgStyle: CSSProperties = {
        height: `95%`,
    };

    return (
        <svg width={width} viewBox="0 0 48 16" fill="none" style={svgStyle} aria-label="MPN Logo">
            <title>{"MPN Logo"}</title>
            <path
                fill={color}
                d="M7.597 7.421h-0.415v-2.413c0-0.19 0.011-0.423 0.035-0.697h-0.010c-0.040 0.162-0.075 0.278-0.106 0.348l-1.208 2.762h-0.202l-1.21-2.742c-0.033-0.073-0.068-0.196-0.106-0.368h-0.010c0.013 0.144 0.020 0.378 0.020 0.702v2.408h-0.4v-3.594h0.548l1.087 2.506c0.082 0.19 0.136 0.334 0.161 0.431h0.017c0.082-0.231 0.139-0.378 0.17-0.441l1.109-2.496h0.521v3.594z"
            ></path>
            <path
                fill={color}
                d="M8.749 7.421h-0.405v-2.566h0.405v2.566zM8.818 3.935c0 0.077-0.026 0.141-0.079 0.192s-0.115 0.077-0.188 0.077c-0.074 0-0.137-0.025-0.188-0.074s-0.077-0.114-0.077-0.194c0-0.072 0.025-0.134 0.075-0.187s0.113-0.079 0.189-0.079c0.076 0 0.139 0.026 0.19 0.078s0.077 0.115 0.077 0.188z"
            ></path>
            <path
                fill={color}
                d="M11.147 7.303c-0.196 0.12-0.427 0.18-0.694 0.18-0.231 0-0.438-0.054-0.621-0.162s-0.327-0.26-0.428-0.457c-0.102-0.197-0.153-0.419-0.153-0.664 0-0.424 0.12-0.765 0.359-1.022s0.558-0.386 0.957-0.386c0.22 0 0.416 0.043 0.585 0.13v0.421c-0.19-0.133-0.39-0.2-0.603-0.2-0.262 0-0.475 0.095-0.638 0.285s-0.246 0.435-0.246 0.735c0 0.299 0.078 0.536 0.233 0.709s0.364 0.261 0.626 0.261c0.22 0 0.428-0.073 0.622-0.221v0.391z"
            ></path>
            <path
                fill={color}
                d="M12.952 5.271c-0.073-0.055-0.175-0.083-0.309-0.083-0.18 0-0.325 0.086-0.437 0.257s-0.168 0.394-0.168 0.668v1.308h-0.405v-2.566h0.405v0.529h0.010c0.056-0.177 0.142-0.317 0.259-0.42s0.251-0.154 0.403-0.154c0.105 0 0.186 0.012 0.242 0.035v0.426z"
            ></path>
            <path
                fill={color}
                d="M15.082 6.143c0-0.322-0.070-0.57-0.211-0.742s-0.342-0.258-0.604-0.258c-0.26 0-0.466 0.088-0.617 0.264s-0.227 0.425-0.227 0.746c0 0.308 0.076 0.548 0.228 0.721s0.358 0.259 0.616 0.259c0.263 0 0.465-0.085 0.605-0.256s0.21-0.415 0.21-0.734zM15.497 6.128c0 0.408-0.114 0.736-0.342 0.984s-0.534 0.372-0.917 0.372c-0.374 0-0.672-0.121-0.895-0.362s-0.335-0.559-0.335-0.954c0-0.424 0.114-0.76 0.343-1.006s0.544-0.37 0.946-0.37c0.375 0 0.669 0.119 0.882 0.357s0.318 0.564 0.318 0.979z"
            ></path>
            <path
                fill={color}
                d="M17.379 6.735c0 0.222-0.084 0.403-0.253 0.541s-0.393 0.208-0.673 0.208c-0.239 0-0.448-0.052-0.627-0.155v-0.441c0.199 0.164 0.418 0.246 0.657 0.246 0.321 0 0.482-0.119 0.482-0.358 0-0.097-0.031-0.176-0.094-0.237s-0.205-0.143-0.427-0.247c-0.224-0.097-0.382-0.201-0.474-0.312s-0.138-0.258-0.138-0.442c0-0.212 0.084-0.389 0.252-0.531s0.381-0.213 0.64-0.213c0.199 0 0.379 0.040 0.541 0.12v0.413c-0.165-0.122-0.356-0.183-0.573-0.183-0.133 0-0.241 0.034-0.322 0.1s-0.122 0.153-0.122 0.258c0 0.114 0.031 0.201 0.094 0.262s0.192 0.133 0.388 0.217c0.24 0.104 0.409 0.212 0.506 0.326s0.146 0.257 0.146 0.429z"
            ></path>
            <path
                fill={color}
                d="M19.727 6.143c0-0.322-0.070-0.57-0.211-0.742s-0.342-0.258-0.604-0.258c-0.26 0-0.466 0.088-0.617 0.264s-0.227 0.425-0.227 0.746c0 0.308 0.076 0.548 0.229 0.721s0.358 0.259 0.616 0.259c0.263 0 0.465-0.085 0.605-0.256s0.21-0.415 0.21-0.734zM20.142 6.128c0 0.408-0.114 0.736-0.342 0.984s-0.534 0.372-0.917 0.372c-0.374 0-0.672-0.121-0.895-0.362s-0.335-0.559-0.335-0.954c0-0.424 0.114-0.76 0.343-1.006s0.544-0.37 0.946-0.37c0.375 0 0.669 0.119 0.882 0.357s0.318 0.564 0.318 0.979z"
            ></path>
            <path
                fill={color}
                d="M21.801 3.983c-0.079-0.045-0.169-0.068-0.269-0.068-0.283 0-0.425 0.181-0.425 0.544v0.396h0.593v0.348h-0.593v2.218h-0.405v-2.218h-0.43v-0.348h0.43v-0.416c0-0.261 0.076-0.471 0.227-0.632s0.344-0.241 0.578-0.241c0.124 0 0.221 0.015 0.294 0.045v0.371z"
            ></path>
            <path
                fill={color}
                d="M23.272 7.396c-0.097 0.055-0.224 0.083-0.38 0.083-0.442 0-0.662-0.252-0.662-0.757v-1.519h-0.435v-0.348h0.435v-0.626l0.405-0.133v0.759h0.637v0.348h-0.637v1.449c0 0.172 0.029 0.294 0.086 0.367s0.154 0.109 0.289 0.109c0.1 0 0.188-0.028 0.262-0.083v0.351z"
            ></path>
            <path
                fill={color}
                d="M9.88 9.872c0-0.476-0.277-0.714-0.83-0.714h-0.486v1.474h0.435c0.285 0 0.503-0.066 0.654-0.197s0.227-0.319 0.227-0.563zM10.315 9.849c0 0.348-0.118 0.628-0.354 0.841s-0.546 0.32-0.93 0.32h-0.467v1.361h-0.415v-3.594h0.973c0.379 0 0.672 0.094 0.88 0.282s0.312 0.452 0.312 0.791z"
            ></path>
            <path
                fill={color}
                d="M12.024 11.072l-0.612 0.088c-0.209 0.030-0.356 0.081-0.44 0.152s-0.126 0.186-0.126 0.345c0 0.129 0.045 0.232 0.136 0.309s0.207 0.117 0.348 0.117c0.201 0 0.367-0.072 0.498-0.216s0.196-0.323 0.196-0.539v-0.256zM12.429 12.37h-0.405v-0.401h-0.010c-0.176 0.309-0.436 0.464-0.778 0.464-0.244 0-0.439-0.067-0.585-0.202s-0.22-0.315-0.22-0.543c0-0.478 0.278-0.757 0.835-0.837l0.758-0.108c0-0.434-0.174-0.652-0.521-0.652-0.305 0-0.58 0.105-0.825 0.313v-0.421c0.074-0.057 0.201-0.112 0.38-0.164s0.339-0.079 0.479-0.079c0.594 0 0.892 0.32 0.892 0.96v1.669z"
            ></path>
            <path
                fill={color}
                d="M14.384 10.22c-0.073-0.055-0.175-0.083-0.309-0.083-0.179 0-0.325 0.086-0.437 0.257s-0.168 0.394-0.168 0.668v1.308h-0.405v-2.566h0.405v0.529h0.010c0.056-0.177 0.142-0.317 0.259-0.42s0.251-0.154 0.403-0.154c0.105 0 0.186 0.012 0.242 0.035v0.426z"
            ></path>
            <path
                fill={color}
                d="M16.271 12.345c-0.097 0.055-0.224 0.083-0.38 0.083-0.441 0-0.662-0.252-0.662-0.757v-1.519h-0.435v-0.348h0.435v-0.627l0.405-0.133v0.759h0.637v0.348h-0.637v1.449c0 0.172 0.029 0.294 0.086 0.367s0.154 0.109 0.289 0.109c0.1 0 0.188-0.028 0.262-0.083v0.351z"
            ></path>
            <path
                fill={color}
                d="M18.936 12.37h-0.405v-1.464c0-0.543-0.196-0.814-0.588-0.814-0.199 0-0.366 0.076-0.5 0.229s-0.201 0.348-0.201 0.585v1.464h-0.405v-2.566h0.405v0.426h0.010c0.189-0.326 0.466-0.489 0.83-0.489 0.278 0 0.49 0.092 0.636 0.276s0.219 0.444 0.219 0.782v1.571z"
            ></path>
            <path
                fill={color}
                d="M21.171 10.842c-0.003-0.237-0.060-0.421-0.169-0.553s-0.264-0.197-0.463-0.197c-0.188 0-0.348 0.069-0.481 0.206s-0.217 0.318-0.252 0.544h1.366zM21.586 11.19h-1.785c0.007 0.289 0.082 0.51 0.226 0.663s0.344 0.229 0.599 0.229c0.286 0 0.547-0.093 0.783-0.281v0.383c-0.221 0.165-0.514 0.248-0.879 0.248-0.361 0-0.642-0.118-0.845-0.355s-0.304-0.563-0.304-0.981c0-0.254 0.051-0.486 0.152-0.695s0.241-0.372 0.419-0.487c0.178-0.115 0.375-0.173 0.593-0.173 0.328 0 0.583 0.108 0.767 0.325s0.275 0.519 0.275 0.908v0.216z"
            ></path>
            <path
                fill={color}
                d="M23.371 10.22c-0.072-0.055-0.175-0.083-0.309-0.083-0.18 0-0.325 0.086-0.437 0.257s-0.168 0.394-0.168 0.668v1.308h-0.405v-2.566h0.405v0.529h0.010c0.056-0.177 0.142-0.317 0.259-0.42s0.251-0.154 0.402-0.154c0.105 0 0.186 0.012 0.242 0.035v0.426z"
            ></path>
            <path
                fill="none"
                stroke={color}
                strokeLinejoin="miter"
                strokeLinecap="butt"
                strokeMiterlimit="10"
                strokeWidth="0.2743"
                d="M25.381 2.976v10.048"
            ></path>
            <path
                fill={color}
                d="M28.477 6.874c0 0.111-0.039 0.199-0.118 0.262s-0.186 0.095-0.321 0.095c-0.049 0-0.105-0.008-0.167-0.024s-0.105-0.034-0.131-0.052v-0.184c0.038 0.034 0.087 0.062 0.148 0.085s0.119 0.034 0.172 0.034c0.17 0 0.255-0.066 0.255-0.199 0-0.055-0.018-0.103-0.055-0.142s-0.111-0.092-0.224-0.157c-0.109-0.062-0.185-0.12-0.229-0.176s-0.066-0.123-0.066-0.202c0-0.105 0.039-0.19 0.117-0.255s0.179-0.099 0.303-0.099c0.119 0 0.206 0.016 0.26 0.047v0.174c-0.069-0.054-0.159-0.081-0.269-0.081-0.073 0-0.133 0.018-0.18 0.055s-0.069 0.085-0.069 0.145c0 0.044 0.007 0.079 0.020 0.105s0.037 0.053 0.070 0.078c0.033 0.026 0.089 0.061 0.167 0.104 0.117 0.065 0.199 0.127 0.246 0.185s0.070 0.124 0.070 0.199z"
            ></path>
            <path
                fill={color}
                d="M28.897 5.923c0 0.028-0.010 0.052-0.030 0.071s-0.043 0.028-0.070 0.028c-0.028 0-0.051-0.009-0.070-0.027s-0.029-0.042-0.029-0.072c0-0.026 0.009-0.049 0.028-0.068s0.043-0.030 0.071-0.030c0.028 0 0.052 0.010 0.071 0.029s0.029 0.042 0.029 0.069v0zM28.872 7.208h-0.151v-0.946h0.151v0.946z"
            ></path>
            <path fill={color} d="M29.178 7.208h0.151v-1.4h-0.151z"></path>
            <path
                fill={color}
                d="M30.379 6.262l-0.377 0.946h-0.15l-0.357-0.946h0.167l0.24 0.686c0.013 0.036 0.023 0.081 0.031 0.133h0.005c0.006-0.046 0.015-0.088 0.030-0.128l0.252-0.691h0.159z"
            ></path>
            <path
                fill={color}
                d="M31.294 6.773h-0.668c0.002 0.107 0.031 0.188 0.084 0.245s0.129 0.084 0.224 0.084c0.107 0 0.205-0.034 0.293-0.103v0.141c-0.082 0.061-0.192 0.091-0.329 0.091-0.135 0-0.24-0.043-0.316-0.13s-0.114-0.208-0.114-0.362c0-0.094 0.019-0.179 0.056-0.256s0.091-0.138 0.157-0.18c0.067-0.043 0.14-0.064 0.222-0.064 0.123 0 0.218 0.040 0.286 0.12s0.103 0.191 0.103 0.334v0.079zM31.139 6.645c-0.001-0.087-0.023-0.155-0.064-0.203s-0.098-0.073-0.173-0.073c-0.070 0-0.13 0.025-0.18 0.076s-0.081 0.117-0.094 0.2h0.511z"
            ></path>
            <path
                fill={color}
                d="M32.009 6.416c-0.027-0.020-0.066-0.031-0.115-0.031-0.067 0-0.122 0.032-0.164 0.095s-0.063 0.145-0.063 0.246v0.482h-0.151v-0.946h0.151v0.195h0.004c0.021-0.065 0.053-0.117 0.097-0.154s0.094-0.057 0.15-0.057c0.039 0 0.070 0.004 0.091 0.013v0.157z"
            ></path>
            <path
                fill={color}
                d="M33.698 6.529c0 0.135-0.030 0.254-0.089 0.357s-0.143 0.182-0.252 0.238c-0.109 0.055-0.234 0.083-0.374 0.083h-0.35v-1.324h0.366c0.466 0 0.699 0.215 0.699 0.646v0zM33.535 6.533c0-0.339-0.18-0.509-0.54-0.509h-0.207v1.044h0.197c0.175 0 0.31-0.047 0.406-0.139s0.144-0.225 0.144-0.395z"
            ></path>
            <path
                fill={color}
                d="M34.608 7.208h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.039-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM34.457 6.73l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path
                fill={color}
                d="M35.331 7.199c-0.036 0.020-0.084 0.030-0.142 0.030-0.165 0-0.248-0.093-0.248-0.279v-0.56h-0.163v-0.128h0.163v-0.231l0.151-0.049v0.28h0.238v0.128h-0.238v0.534c0 0.063 0.011 0.109 0.032 0.136s0.058 0.040 0.108 0.040c0.037 0 0.070-0.010 0.098-0.030v0.129z"
            ></path>
            <path
                fill={color}
                d="M36.201 7.208h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.039-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM36.049 6.73l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path
                fill={color}
                d="M37.969 7.208h-0.172l-0.139-0.37h-0.562l-0.132 0.37h-0.172l0.506-1.324h0.161l0.511 1.324zM37.606 6.697l-0.208-0.563c-0.005-0.013-0.012-0.043-0.021-0.090h-0.004c-0.006 0.039-0.012 0.069-0.020 0.090l-0.206 0.563h0.459z"
            ></path>
            <path
                fill={color}
                d="M38.929 7.208h-0.152v-0.539c0-0.2-0.073-0.3-0.22-0.3-0.074 0-0.137 0.028-0.187 0.085s-0.075 0.128-0.075 0.215v0.539h-0.151v-0.946h0.151v0.157h0.004c0.071-0.12 0.174-0.18 0.31-0.18 0.104 0 0.183 0.034 0.237 0.102s0.082 0.164 0.082 0.288v0.579z"
            ></path>
            <path
                fill={color}
                d="M39.882 7.208h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.039-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM39.73 6.73l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path fill={color} d="M40.165 7.208h0.151v-1.4h-0.151z"></path>
            <path
                fill={color}
                d="M41.376 6.262l-0.435 1.097c-0.078 0.195-0.187 0.293-0.327 0.293-0.039 0-0.072-0.004-0.099-0.011v-0.136c0.032 0.011 0.062 0.017 0.089 0.017 0.077 0 0.135-0.045 0.173-0.136l0.076-0.18-0.369-0.944h0.169l0.255 0.728 0.019 0.072h0.006c0.002-0.014 0.009-0.037 0.018-0.069l0.269-0.731h0.157z"
            ></path>
            <path
                fill={color}
                d="M41.975 7.199c-0.036 0.020-0.084 0.030-0.142 0.030-0.165 0-0.248-0.093-0.248-0.279v-0.56h-0.163v-0.128h0.163v-0.231l0.151-0.049v0.28h0.238v0.128h-0.238v0.534c0 0.063 0.011 0.109 0.032 0.136s0.058 0.040 0.108 0.040c0.037 0 0.070-0.010 0.098-0.030v0.129z"
            ></path>
            <path
                fill={color}
                d="M42.353 5.923c0 0.028-0.010 0.052-0.030 0.071s-0.043 0.028-0.070 0.028c-0.028 0-0.051-0.009-0.070-0.027s-0.029-0.042-0.029-0.072c0-0.026 0.009-0.049 0.028-0.068s0.043-0.030 0.071-0.030 0.052 0.010 0.071 0.029c0.019 0.019 0.029 0.042 0.029 0.069v0zM42.327 7.208h-0.151v-0.946h0.151v0.946z"
            ></path>
            <path
                fill={color}
                d="M43.271 7.165c-0.073 0.044-0.16 0.066-0.26 0.066-0.086 0-0.164-0.020-0.233-0.059s-0.122-0.096-0.16-0.169-0.057-0.154-0.057-0.245c0-0.156 0.045-0.282 0.134-0.377s0.209-0.142 0.358-0.142c0.083 0 0.155 0.016 0.219 0.048v0.155c-0.071-0.049-0.146-0.074-0.225-0.074-0.098 0-0.178 0.035-0.239 0.105s-0.091 0.16-0.091 0.271c0 0.11 0.029 0.197 0.087 0.261s0.137 0.096 0.235 0.096c0.083 0 0.16-0.027 0.233-0.081v0.144z"
            ></path>
            <path
                fill={color}
                d="M44.016 6.955c0 0.082-0.032 0.148-0.095 0.199s-0.147 0.077-0.251 0.077c-0.089 0-0.167-0.019-0.235-0.057v-0.163c0.074 0.060 0.156 0.091 0.246 0.091 0.12 0 0.18-0.044 0.18-0.132 0-0.036-0.012-0.065-0.035-0.087s-0.077-0.053-0.16-0.091c-0.084-0.036-0.143-0.074-0.177-0.115s-0.052-0.096-0.052-0.163c0-0.078 0.031-0.144 0.094-0.196s0.142-0.078 0.239-0.078c0.074 0 0.142 0.015 0.202 0.044v0.152c-0.062-0.045-0.133-0.067-0.214-0.067-0.050 0-0.090 0.012-0.121 0.037s-0.045 0.056-0.045 0.095c0 0.042 0.012 0.074 0.035 0.097s0.072 0.049 0.145 0.079c0.090 0.038 0.153 0.078 0.189 0.12s0.055 0.095 0.055 0.158z"
            ></path>
            <path
                fill={color}
                d="M28.477 9.593c0 0.111-0.039 0.199-0.118 0.262s-0.186 0.095-0.321 0.095c-0.049 0-0.105-0.008-0.167-0.024s-0.105-0.034-0.131-0.052v-0.184c0.038 0.034 0.087 0.062 0.148 0.085s0.119 0.034 0.172 0.034c0.17 0 0.255-0.066 0.255-0.199 0-0.055-0.018-0.103-0.055-0.142s-0.111-0.092-0.224-0.157c-0.109-0.062-0.185-0.12-0.229-0.175s-0.066-0.123-0.066-0.202c0-0.105 0.039-0.19 0.117-0.255s0.179-0.099 0.303-0.099c0.119 0 0.206 0.016 0.26 0.047v0.174c-0.069-0.054-0.159-0.081-0.269-0.081-0.073 0-0.133 0.018-0.18 0.055s-0.069 0.085-0.069 0.145c0 0.044 0.007 0.079 0.020 0.105s0.037 0.053 0.070 0.079c0.033 0.026 0.089 0.061 0.167 0.104 0.117 0.065 0.199 0.127 0.246 0.185s0.070 0.124 0.070 0.199z"
            ></path>
            <path
                fill={color}
                d="M28.897 8.642c0 0.028-0.010 0.052-0.030 0.071s-0.043 0.028-0.070 0.028c-0.028 0-0.051-0.009-0.070-0.027s-0.029-0.042-0.029-0.072c0-0.026 0.009-0.049 0.028-0.068s0.043-0.030 0.071-0.030c0.028 0 0.052 0.010 0.071 0.029s0.029 0.042 0.029 0.069v0zM28.872 9.927h-0.151v-0.946h0.151v0.946z"
            ></path>
            <path fill={color} d="M29.178 9.927h0.151v-1.4h-0.151z"></path>
            <path
                fill={color}
                d="M30.379 8.981l-0.377 0.946h-0.15l-0.357-0.946h0.167l0.24 0.686c0.013 0.036 0.023 0.081 0.031 0.133h0.005c0.006-0.046 0.015-0.088 0.030-0.128l0.252-0.691h0.159z"
            ></path>
            <path
                fill={color}
                d="M31.294 9.492h-0.668c0.002 0.107 0.031 0.188 0.084 0.245s0.129 0.084 0.224 0.084c0.107 0 0.205-0.034 0.293-0.103v0.141c-0.082 0.061-0.192 0.091-0.329 0.091-0.135 0-0.24-0.043-0.316-0.13s-0.114-0.208-0.114-0.362c0-0.094 0.019-0.179 0.056-0.256s0.091-0.138 0.157-0.18c0.067-0.042 0.14-0.064 0.222-0.064 0.123 0 0.218 0.040 0.286 0.12s0.103 0.191 0.103 0.334v0.079zM31.139 9.364c-0.001-0.087-0.023-0.155-0.064-0.203s-0.098-0.073-0.173-0.073c-0.070 0-0.13 0.025-0.18 0.076s-0.081 0.117-0.094 0.2h0.511z"
            ></path>
            <path
                fill={color}
                d="M32.009 9.135c-0.027-0.020-0.066-0.030-0.115-0.030-0.067 0-0.122 0.032-0.164 0.095s-0.063 0.145-0.063 0.246v0.482h-0.151v-0.946h0.151v0.195h0.004c0.021-0.065 0.053-0.117 0.097-0.154s0.094-0.057 0.15-0.057c0.039 0 0.070 0.004 0.091 0.013v0.157z"
            ></path>
            <path
                fill={color}
                d="M33.698 9.248c0 0.135-0.030 0.254-0.089 0.357s-0.143 0.182-0.252 0.238c-0.109 0.055-0.234 0.083-0.374 0.083h-0.35v-1.324h0.366c0.466 0 0.699 0.215 0.699 0.646v0zM33.535 9.252c0-0.339-0.18-0.509-0.54-0.509h-0.207v1.044h0.197c0.175 0 0.31-0.047 0.406-0.139s0.144-0.225 0.144-0.395z"
            ></path>
            <path
                fill={color}
                d="M34.608 9.927h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.038-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM34.457 9.449l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path
                fill={color}
                d="M35.331 9.918c-0.036 0.020-0.084 0.030-0.142 0.030-0.165 0-0.248-0.093-0.248-0.279v-0.56h-0.163v-0.128h0.163v-0.231l0.151-0.049v0.28h0.238v0.128h-0.238v0.534c0 0.063 0.011 0.109 0.032 0.136s0.058 0.040 0.108 0.040c0.037 0 0.070-0.010 0.098-0.030v0.129z"
            ></path>
            <path
                fill={color}
                d="M36.201 9.927h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.038-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM36.049 9.449l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path
                fill={color}
                d="M37.754 8.998c0 0.128-0.044 0.232-0.133 0.31s-0.204 0.117-0.347 0.117h-0.175v0.502h-0.155v-1.324h0.364c0.142 0 0.251 0.035 0.329 0.104s0.117 0.166 0.117 0.291v0zM37.591 9.006c0-0.175-0.103-0.263-0.31-0.263h-0.182v0.543h0.163c0.107 0 0.188-0.024 0.245-0.072s0.085-0.118 0.085-0.208z"
            ></path>
            <path fill={color} d="M37.983 9.927h0.151v-1.4h-0.151z"></path>
            <path
                fill={color}
                d="M39.11 9.927h-0.151v-0.148h-0.004c-0.066 0.114-0.163 0.171-0.291 0.171-0.091 0-0.164-0.025-0.219-0.074s-0.082-0.117-0.082-0.2c0-0.176 0.104-0.279 0.312-0.308l0.284-0.040c0-0.16-0.065-0.24-0.195-0.24-0.114 0-0.217 0.038-0.308 0.115v-0.155c0.028-0.021 0.075-0.041 0.142-0.060s0.127-0.030 0.179-0.030c0.222 0 0.333 0.118 0.333 0.354v0.615zM38.959 9.449l-0.229 0.032c-0.078 0.011-0.133 0.030-0.164 0.056s-0.047 0.068-0.047 0.127c0 0.047 0.017 0.086 0.051 0.115s0.077 0.042 0.13 0.042c0.075 0 0.137-0.026 0.186-0.079s0.074-0.119 0.074-0.199v-0.094z"
            ></path>
            <path
                fill={color}
                d="M39.832 9.918c-0.036 0.020-0.084 0.030-0.142 0.030-0.165 0-0.248-0.093-0.248-0.279v-0.56h-0.163v-0.128h0.163v-0.231l0.151-0.049v0.28h0.238v0.128h-0.238v0.534c0 0.063 0.011 0.109 0.032 0.136s0.058 0.040 0.108 0.040c0.038 0 0.070-0.010 0.098-0.030v0.129z"
            ></path>
            <path
                fill={color}
                d="M40.5 8.66c-0.030-0.017-0.063-0.025-0.101-0.025-0.106 0-0.159 0.067-0.159 0.2v0.146h0.222v0.128h-0.222v0.817h-0.152v-0.817h-0.161v-0.128h0.161v-0.153c0-0.096 0.028-0.174 0.085-0.233s0.129-0.089 0.216-0.089c0.046 0 0.083 0.006 0.11 0.017v0.137z"
            ></path>
            <path
                fill={color}
                d="M41.482 9.45c0 0.15-0.043 0.271-0.128 0.363s-0.199 0.137-0.343 0.137c-0.14 0-0.251-0.044-0.335-0.133s-0.125-0.207-0.125-0.352c0-0.156 0.043-0.28 0.128-0.37s0.204-0.137 0.354-0.137c0.14 0 0.25 0.044 0.33 0.132s0.119 0.207 0.119 0.36v0zM41.327 9.456c0-0.119-0.026-0.21-0.079-0.273s-0.127-0.095-0.225-0.095c-0.097 0-0.174 0.033-0.231 0.098s-0.085 0.156-0.085 0.274c0 0.113 0.028 0.202 0.085 0.266s0.134 0.095 0.231 0.095c0.098 0 0.174-0.031 0.226-0.094s0.078-0.153 0.078-0.271z"
            ></path>
            <path
                fill={color}
                d="M42.21 9.135c-0.027-0.020-0.066-0.030-0.115-0.030-0.067 0-0.122 0.032-0.164 0.095s-0.063 0.145-0.063 0.246v0.482h-0.151v-0.946h0.151v0.195h0.004c0.021-0.065 0.053-0.117 0.097-0.154s0.094-0.057 0.15-0.057c0.039 0 0.070 0.004 0.091 0.013v0.157z"
            ></path>
            <path
                fill={color}
                d="M43.717 9.927h-0.151v-0.543c0-0.106-0.016-0.182-0.049-0.227s-0.086-0.069-0.163-0.069c-0.065 0-0.12 0.030-0.165 0.089s-0.067 0.13-0.067 0.212v0.539h-0.151v-0.562c0-0.185-0.072-0.278-0.215-0.278-0.066 0-0.121 0.028-0.164 0.084s-0.065 0.127-0.065 0.216v0.539h-0.151v-0.946h0.151v0.15h0.004c0.067-0.115 0.165-0.173 0.293-0.173 0.062 0 0.117 0.017 0.166 0.052s0.083 0.083 0.102 0.145c0.070-0.131 0.175-0.197 0.314-0.197 0.208 0 0.312 0.129 0.312 0.386v0.583z"
            ></path>
        </svg>
    );
};

export default MPNLogo;
