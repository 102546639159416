import React, { PropsWithChildren } from "react";
import { graphql } from "gatsby";
import { mergeStyleSets, Stack, IStackTokens, useTheme } from "@fluentui/react";

import Layout from "../components/Layout";
import SEO from "../components/Seo";
import Markdown from "../components/Markdown";
import LinkButton from "../components/LinkButton";
import Background from "../components/logo/Background";
import LinkRegular from "../components/LinkRegular";
import MPNLogo from "../components/logo/MPNLogo";
import Logo from "../components/logo/Logo";
import LinkPrimaryBackground from "../components/LinkPrimaryBackground";

export interface PageGraphQL {
    data: {
        markdownRemark: {
            htmlAst: string;
        };
    };
}

const IndexPage: React.FunctionComponent<PageGraphQL> = (props: PropsWithChildren<PageGraphQL>) => {
    const { data } = props;
    const theme = useTheme();

    const mainStyle = mergeStyleSets({
        indexRoot: {
            padding: `0px`,
        },
        headerRoot: {
            width: `100vw`,
            height: `100vh`,
            color: theme.palette.white,
            background: `${theme.palette.themeDark} no-repeat center center`,
            selectors: {
                "@media(max-width: 600px)": {
                    width: `100%`,
                },
            },
        },
        contentRoot: {
            selectors: {
                "@media(max-width: 600px)": {
                    width: `100%`,
                },
            },
        },
        itemRoot: {
            position: `relative` as any,
            width: `90%`,
            maxWidth: `1000px`,
            margin: `0 auto`,
            textAlign: `center`,
        },
        logo: {
            width: `100%`,
            height: `100%`,
        },
        mpnLogo: {
            position: `absolute` as any,
            right: `0px`,
            top: `calc(100vh - 70px)`,
            maxWidth: `250px`,
            minWidth: `72px`,
            //margin: `2px`,
            selectors: {
                "@media(max-width: 400px)": {
                    right: `unset`,
                    alignSelf: `center`,
                    width: `100%`,
                    maxWidth: `unset`,
                    textAlign: `center`,
                },
            },
        },
        disclaimer: {
            position: `absolute` as any,
            top: `calc(100vh - 20px)`,
            width: `100%`,
            textAlign: `center`,
            color: theme.palette.white,
        },
    });

    const stackTokens: IStackTokens = {
        childrenGap: 25,
    };

    return (
        <Layout isIndex noHeader noFooter>
            <SEO title="Home" />
            <div className={mainStyle.indexRoot}>
                <Stack grow horizontal horizontalAlign="center" className={mainStyle.headerRoot}>
                    <Background />
                    <Stack grow verticalAlign="space-between" verticalFill className={mainStyle.contentRoot}>
                        <Stack.Item grow>{` `}</Stack.Item>
                        <Stack.Item className={mainStyle.itemRoot}>
                            <Stack tokens={stackTokens} horizontalAlign="center">
                                <div className={mainStyle.logo}>
                                    <LinkRegular href="https://datascenarios.nl">
                                        <Logo />
                                    </LinkRegular>
                                </div>
                                <Markdown htmlAst={data.markdownRemark.htmlAst} />
                                <LinkButton primary center url="/main/" title="Enter site">
                                    Enter site
                                </LinkButton>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item grow>{` `}</Stack.Item>
                    </Stack>
                </Stack>
                <div className={mainStyle.mpnLogo}>
                    <LinkRegular href="https://www.microsoft.com/en-nl/solution-providers/partnerdetails/datascenarios_187615f5-abbb-49ad-bb8f-25336ff1e97e/02608d9c-33d7-4175-84c5-a44022b97906">
                        <MPNLogo width={180} />
                    </LinkRegular>
                </div>
                <div className={mainStyle.disclaimer}>
                    This site is provided by{" "}
                    <LinkPrimaryBackground href="https://datascenarios.nl">DataScenarios</LinkPrimaryBackground>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query ($path: String!) {
        markdownRemark(frontmatter: { path: { eq: $path } }) {
            htmlAst
        }
    }
`;

export default IndexPage;
